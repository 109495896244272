.App {
  text-align: center;
}

/* BAHNSCHRIFT 10.TTF */

/* @font-face {
  font-family: "GoldmanBold";
  src: local("GoldmanBold"),
   url("./fonts/BAHNSCHRIFT\ 10.TTF") format("truetype");
  font-weight: bold;
  } */

  @font-face {
    font-family: "Segoe UI";
    font-weight: 600;
    src: local("Segoe UI Semibold");
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.main-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 260px;
  height: 100%;
  background-color: #92c99f;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  left: 0;
}

.recharts-legend-wrapper {
  bottom: -15px !important
}


.toggle .main-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 260px;
  height: 100%;
  background-color: #92c99f;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  left: -100%;
}

.main-content {
  position: relative;
  z-index: 10;
  margin-left: 280px;
  margin-right: 20px;
  padding-top: 20px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.footer-content {
  position: relative;
  z-index: 0;
  margin-left: 260px;
  padding-top: 20px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  margin-top: auto;
}

.toggle .footer-content {
  position: relative;
  z-index: 0;
  margin-left: 0px;
  padding-top: 20px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  margin-top: auto;
}

.toggle .main-content {
  position: relative;
  z-index: 10;
  margin-left: 15px;
  margin-right: 20px;
  padding-top: 20px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.toggle .menu-icon {
  margin-left: 15px!important;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.menu-icon {
  margin-left: 280px!important;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}


@media (max-width:600px) {
    .main-menu {
          width: 250px;
          height: 100vh;
          display: flex;
          justify-content: center;
          position: fixed;
          top: 0;
          left: -100%;
          transition: 350ms;
          z-index: 1000;
          height: 100%;
          cursor: pointer;
          margin-top: 78px;
    }
    .toggle .main-menu { 
          width: 250px;
          height: 100vh;
          display: flex;
          justify-content: center;
          position: fixed;
          top: 0;
          left: -0%;
          transition: 350ms;
          z-index: 1000;
          height: 100%;
          cursor: pointer;
    }
    .main-content {
      margin-left: 15px!important;
      margin-right: 15px;
    }
    .footer-content {
      margin-left: 0px!important;
    }
    .menu-icon {
      margin-left: 15px!important;
    }

    .header-logo {
      display: none!important;
    } 
}

.header-logo {
  width: 100%;
  padding: 20px;
  font-size: 25px;
  overflow: hidden;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: unset;
  text-align: center;
}

.hoveritem:hover svg{
  color: #92c99f !important;
}

.menu-container {
  height: 100%;
  overflow: auto;
  padding-bottom: 290px;
}

.activeItem {
  background: #efefef;
	border-left: 6px solid #92c99f;
	border-right: 6px solid #92c99f;
	cursor: pointer;
	color: #92c99f!important;
}

.activeItem svg{
  color: #92c99f !important;
  
}

.subactiveItem {
  background: #efefef;
  cursor: pointer;
	color: #92c99f!important;
}

.subactiveItem svg{
  color: #92c99f !important;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  background-color: #ffffff;
  top: -3px;
  padding-left: 5px;
  padding-right: 5px;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root{
  background-color: #ffffff;
  top: -3px!important;
  padding-left: 5px!important;
  padding-right: 5px!important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  margin-bottom: unset;
}

.MuiTablePagination-displayedRows {
  margin-bottom: unset;
}

.pac-container{
  z-index: 2000;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.4375em!important;
}


.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  margin-top: -9px!important;
}

.border-color{
  border: 1px gray solid
}

.card {
  transition: all 0.5s;
  /* border: 0; */
  position: relative;
  width: 100%;
  box-shadow: none;
  background: transparent;
}

.w_icon.indigo {
  background: rgba(153,136,255,0.3);
  color: #98f;
}

.w_icon {
  -webkit-border-radius: 54px;
  -moz-border-radius: 54px;
  -ms-border-radius: 54px;
  border-radius: 54px;
  position: relative;
  margin: auto;
  height: 54px;
  width: 54px;
  line-height: 54px;
}

.w_data_1 {
  text-align: center;
}

.card-body{
  font-weight: 400;
    border-radius: 0.35rem;
    background: #fff;
    font-size: 14px;
    color: #222;
    padding: 20px;
}

.table-footer {
  background:  linear-gradient(12deg, rgba(80,0,137,1) 40%, rgba(212,109,255,1) 100%);
  padding: 10px;
}

.header-table{
  background:  linear-gradient(12deg, rgba(80,0,137,1) 40%, rgba(212,109,255,1) 100%);
  padding-top: 20px;
}


.spcl .css-1o2jng6-MuiOutlinedInput-notchedOutline{
  background: #fff;
  border: unset;
  color: #495057;
}

.clr .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  color: #fff;
}

.tableheight .table-responsive {
    height: 200px;
    overflow-y: scroll;
}

.tableheight1 .table-responsive {
  max-height: 500px;
  overflow-y: scroll;
}

.pospayable{
  margin-top: 15px;
  padding-left: 10px;
  width: 100%;
  border: 2px solid #0cb917;
  font-weight: bold !important;
  background-color: #0cb917 !important;
  color: #fff !important;
  font-size: 20px !important;
  border-radius: 6px !important;
}
.pospayable1{
  margin-top: 30px;
  padding-left: 10px;
  width: 100%;
  border: 2px solid #ec2525 !important;
  font-weight: bold !important;
  background-color: #ec2525 !important;
  color: #fff !important;
  font-size: 20px !important;
  border-radius: 6px !important;
}
.dash-numbers{
  height: 172px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 10%);
}

.dash-round {
  padding: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #E6EFFF;
  margin-left: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-gradient-primary {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff)) !important;
  background: linear-gradient(to right, #da8cff, #9a55ff) !important;
}

.erp-icon-rectangle {
  width: 61px;
  height: 61px;
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 1rem;
  color: #ffffff;
  padding-top: 15px;
}

.bg-gradient-warning, .datepicker-custom .datepicker.datepicker-inline .datepicker-days .table-condensed tbody tr td.day.active:before {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500)) !important;
  background: linear-gradient(to right, #f6e384, #ffd500) !important;
}

.bg-gradient-success, .datepicker-custom .datepicker.datepicker-inline .datepicker-days .table-condensed tbody tr td.day.today:before {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae)) !important;
  background: linear-gradient(to right, #84d9d2, #07cdae) !important;
}

.bg-gradient-danger {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096)) !important;
  background: linear-gradient(to right, #ffbf96, #fe7096) !important;
}

.scrolling-div{
  height: 150px;
  overflow: scroll;
}

.scrolling-div li a {
  font-size: 13px!important;
}

.avatardiv{
    width: 35%;
    max-width: 75px;
    margin: 0 auto;
}

.logodiv{
  /* width: 100%; */
  width: 118px;
  height: 105px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}


.avatarimage {
  background: #fff;
  z-index: 1000;
  position: inherit;
  padding: 2px;
  box-shadow: 0 5px 25px 0 rgb(0 0 0 / 20%);
  border-radius: 15%;
}

@media (max-width: 520px) {
  .main-menu{
    display: block!important;
  }
  
}

/* @media print {
  body * {
    visibility: hidden;
  }
  
  .section-to-print, .section-to-print * {
    visibility: visible !important;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
   
    page-break-after:auto !important;
    visibility: visible !important;
    display: block !important;
  }

  .print_btn{
    display:none;
    }
    .tablerowPrint {
      page-break-inside: avoid!important;
      border: 1px solid #000!important;
      font-family: "GoldmanBold"!important;
    } 
    .tablecellPrint {
      page-break-inside: avoid!important;
      border: 1px solid #000!important;
      font-family: "GoldmanBold"!important;
    }
    .captiontable {
      caption-side: top!important;
      text-align: center!important;
      font-family: "GoldmanBold"!important;
    }

    body * {
      visibility: hidden;
    }
  
    .printing * {
      visibility: visible;
      height: 100%;
    }
  
    .printing {
      display: block;
      visibility: visible;
      -webkit-print-color-adjust: exact;
    }
    .tableheaderexe {
      display: none!important;
      position: fixed!important;
      top: 0!important;
     
    }
    .tablebodyerexe {
      padding-top: 100px!important; ..............................
     
    }
  
    .tablerowPrint {
        page-break-inside: avoid!important;
        border: 1px solid #000;
        font-family: "GoldmanBold"!important;
    } 
    .tablecellPrint {
        page-break-inside: avoid!important;
        border: 1px solid #000;
        font-family: "GoldmanBold"!important;
    }  

    body * {
      visibility: hidden;
    }
    .noprint{
      display: none !important;
    }
    .page-break { display: block; page-break-before: always; ..............................
     }
  
    .tablecellPrint {
      page-break-inside: avoid!important;
      border: 1px solid #000!important;
      -webkit-print-color-adjust: exact;
      border-left: 1px solid #000;
      max-height: 10px!important;
      font-size: 18px!important;
      padding: 2px!important;
    }
    .section-to-print1 * {
      visibility: visible!important;
    }
    .section-to-print1 {
      position: absolute;
      left: 0;
      top: 0;
      page-break-after:auto ;
      -webkit-print-color-adjust: exact;
      border-left: 1px solid #000!important;
    }
  
    .nondisplay {
      display: none!important;
    }
  
    .print_btn1{
      display:none;
      }
      .tablerowPrint1 {
        page-break-inside: avoid!important;
        border: 1px solid #000!important;
        -webkit-print-color-adjust: exact;
        height: 50px!important;
        
      } 
      .tablecellPrint1 {
        page-break-inside: avoid!important;
        border: 1px solid #000!important;
        -webkit-print-color-adjust: exact;
        border-left: 1px solid #000;
        max-height: 10px!important;
        font-size: 16px!important;
      }
    #section-to-print1, #section-to-print1 * {
      visibility: visible!important;
    }
    #section-to-print1 {
      position: absolute!important;
      left: 0!important;
      top: 0!important;
      right: 0!important;
      break-inside: avoid!important;
    }
    .section-to-print1 tr td  {
      border: solid #000 !important;
      border-width: 0 1px 1px 0 !important;
    } 
    @page {
      size: auto;
      margin: 5mm;
    }
    .tablex{
      display: block!important;
    }

    @page {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
  }
  html, body { ..............................
      width: 21mm !important;
      height:297mm !important;
      overflow-y: hidden !important;
      overflow-x: hidden !important;
      ................................................
  }
  .invoice-box
  {
    margin: 0px 0px 0px 0px; ..............................
    
    margin-top: -25px !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
  }




    
} */



.printbutton1{
  display: none;
}
.printdivorg{
  display: none;
}

@media print{
  body *{
    background-color: none;
    
  }
  
  .set12{

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
  }

  .printbutton1{
    display: none !important;
  }
  .printdivorg{
    display: block !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
  }
  .printdiv{
    display: none;
  }
  .bar1{
    display: none;
    visibility: hidden !important;
  
  }

  .table-container {
    /* position: relative; */
    page-break-inside: avoid;
    page-break-after: auto;
  }


  .rowbody{
    height: 37px !important ;
    position: relative;
    border-bottom: none !important;
    border-bottom-style: hidden !important;
  }

/* barcode modal */

  .main-content{
    display: none;
    background-color: white;
  }

  .section-to-print{
    background: none;
  }

  .mmm{
    display: none !important;
  }

  .nem{
    display: none !important;
  }



  /* .web{
    max-width: 84px !important;
  }
  .total4{
    max-width: 95px !important;
  }
  .name3{
    width: 340px !important;
  }
  .item2{
    width: 87px !important;

  } */
 

  .footer-content{
    display: none;
  }
  .printbutton{
    display: none !important;
  }
  .col1{
    margin-top: 0px !important;
  }
  .printdiv{

    
    /* margin-top: -100px !important; */
  height: 297mm !important;
    /* background-color: #61dafb; */
    
    /* height: 255mm !important;
    width: 180mm !important; */
    
    /* align-items: center; */
    
    /* margin-left: 60px !important; */
    
  }
  .invoicetable{
    margin-bottom: 0px !important;
  }
 .ftr{
  margin-top: 0px !important;
 }
  .dashboardlayout{
    margin-top: 0px !important;
    height: 297mm !important;
    /* background-color: #495057; */
  }
  /* .invoice-box{
    height: 100% !important;
    width: 100% !important;
    margin-bottom: end !important;
  } */

  .printing{
    height: 297mm !important;
    width: 100% !important;
  }
  /* .logo123{
    width: 100px;
    height: 90px;
    background-size: cover;
    background-image: url('./assets/images/mjt\ logo.cpy.png');
} */

  

                                   
 
  
}

.captiontable {
  caption-side: top!important;
  text-align: center!important
}


.headerpos{
  position:sticky;
  top: -2px ;
  z-index: 10000;
  background-color: #92c99f;
}

.headerpos1{
  position:sticky;
  top: -2px ;
  z-index: 10000;
  background-color: #ffffff;
}

.tablecellPrint {
  page-break-inside: avoid!important;
  border: 1px solid #000!important;
  -webkit-print-color-adjust: exact;
  height: 10px!important;
  font-size: 13px;
  padding: 2px!important;
  
}

/* newstyle */


.tablerowPrint1 {
  page-break-inside: avoid!important;
  border: 1px solid #000!important;
  -webkit-print-color-adjust: exact;
  height: 10px!important;
  
} 
.tablecellPrint1 {
  page-break-inside: avoid!important;
  border: 1px solid #000!important;
  -webkit-print-color-adjust: exact;
  height: 10px!important;
}

.captiontable1 {
  font-size: 20px!important;
  caption-side: top!important;
  text-align: center!important
}

.tablex {
  display: none!important;
}

.billliststyle{
  font-size: 12px;
}

/* Chrome, Safari, Edge, Opera */
.spclinput input::-webkit-outer-spin-button,
.spclinput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.table-bordered {
  border : 1px solid red;
}
/* .bgsize{
  height: 400px;
} */


.dashboardlayout{
  margin-top: 80px;
}

.btn1{
  background-color: #92c99f !important;
  color: white !important;
  /* border-color: none; */
  border: none !important;
}
.bg12{
  background: #92c99f;
}
.bg123{
  background-color: #92c99f;
}
@media (max-width:991px){
  .retail-btn{
    background-color: #92c99f;
    color: white;
    margin-top: 20px;
  }
}

.sidebar-logo{
  background-image: url('././assets/images/Awfiya_Logo_bg_remove.png');
  width: 118px;
  height: 105px;
  background-size: cover;
}

.center-logo{
  width: 100px;
  height: 90px;
  background-size: cover;
  background-image: url('./assets/images/Awfiya_Logo_bg_remove.png');
}
.table-container::before {
  content: "";
  position: absolute;
  text-align: center;
  justify-content: center;
  margin-left: 200px;
  margin-top: 150px;
  top: 0;
  left: 0;
  width: 40%;
  height: 40%;
  background: url('./assets/images/Awfiya_Logo_bg_remove.png') center center no-repeat;
  background-size: contain; /* adjust this property based on your image size preference */
  opacity: 0.1; /* set the opacity for the watermark */
  pointer-events: none; /* make sure the watermark doesn't interfere with interactions */
}

.btn-products{
  background-color: #92c99f !important;
}
/* .btn-section{
  background-color: #92c99f !important;
} */

.master-logo{
  background-image: url('./assets/images/Awfiya_Logo_bg_remove.png') !important;
 background-size: cover;
 height: 80px !important;
 width: 88px !important;
 margin-left: 15px;
}

.mstr-logo{
  background-image: url('./assets/images/Awfiya_Logo_bg_remove.png') !important;
  background-size: cover;
  width: 82px;
  height: 79px;
  margin-top: 10px;
}

.check{
  height: 80px;
  width: 100%;
  background-color: #92c99f !important;
  display: block;
}

.return{
  float: right !important; 
  margin-right: 20 !important;
  background-color: #92c99f !important;
  color: white !important;
  border-radius: 20px !important;
  margin-top: 20 !important;

}
.retail-btn{
  background-color: #92c99f !important;
}

.clears{
  background-color: #92c99f !important;
  border:none !important;
}

